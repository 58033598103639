import "../sass/thermometer.scss";
const sanityClient = require("@sanity/client");
const client = sanityClient({
  projectId: "1f8hrwai",
  dataset: "production",
  useCdn: true,
  apiVersion: "2021-06-07",
});

const prefixClassName = (className, joint) => {
  return ["fundraising-thermometer", className]
    .filter((part) => !!part)
    .join(joint || "--");
};

const intcomma = (value) => {
  value = value.toString().split(".");
  const int = value[0];
  const parts = [int.replace(/\B(?=(\d{3})+(?!\d))/g, ",")];
  if (value.length > 1) {
    parts.push(value.slice(1).join("."));
  }
  return parts.join(".");
};

class FundraisingThermometer extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    const script = document.querySelector('[src$="thermometer.js"]');
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = script.src.replace(".js", ".css");

    this.wrapper = document.createElement("div");
    this.wrapper.classList.add(prefixClassName());

    this.header = document.createElement("header");
    this.header.classList.add(prefixClassName("header"));
    this.wrapper.appendChild(this.header);

    this.gauge = document.createElement("div");
    this.gauge.classList.add(prefixClassName("gauge"));
    this.wrapper.appendChild(this.gauge);

    this.scaleLines = document.createElement("ol");
    this.scaleLines.classList.add(prefixClassName("scale"));
    [...Array(11).keys()].map((i) => {
      const line = document.createElement("li");
      line.classList.add(prefixClassName("scale--line"));
      if ([0, 5, 10].indexOf(i) > -1) {
        const label = document.createElement("span");
        label.innerText = `${i * 10}%`;
        line.append(label);
      }
      this.scaleLines.insertBefore(line, this.scaleLines.firstChild);
    });
    this.gauge.appendChild(this.scaleLines);

    this.bulb = document.createElement("span");
    this.bulb.classList.add(prefixClassName("bulb"));
    this.gauge.appendChild(this.bulb);

    this.mercury = document.createElement("span");
    this.mercury.classList.add(prefixClassName("mercury"));
    this.mercuryWrapper = document.createElement("span");
    this.mercuryWrapper.classList.add(prefixClassName("mercury--wrapper"));
    this.mercuryWrapper.appendChild(this.mercury);
    this.gauge.appendChild(this.mercuryWrapper);

    this.shadowRoot.append(this.wrapper, link);

    this.fetchTimeout = null;
  }

  connectedCallback() {
    this.uuid = this.getAttribute("uuid");
    this.wrapper.style.setProperty(
      "--theme-background",
      this.getAttribute("background") || "var(--background)"
    );
    this.wrapper.style.setProperty(
      "--theme-text",
      this.getAttribute("text") || "var(--text)"
    );
    this.wrapper.style.setProperty(
      "--theme-mercury",
      this.getAttribute("mercury") || "var(--mercury)"
    );
    this.fetchData();
    window.thermometers = window.thermometers || {};
    window.thermometers[this.uuid] = this;
  }

  fetchData() {
    clearTimeout(this.fetchTimeout);
    client
      .fetch(`*[_type == "campaign" && _id == "${this.uuid}"]`, {})
      .then((campaigns) => {
        if (campaigns && campaigns.length) {
          const campaign = campaigns[0];
          this.setGoalText(campaign.goal);
          this.wrapper.classList.add(prefixClassName("ready", "__"));
          this.setMercuryLevel(campaign.raised || 0, campaign.goal);
          this.fetchTimeout = setTimeout(this.fetchData, 5 * 60 * 1000);
        }
      })
      .catch(console.error);
  }

  setGoalText(goal) {
    this.header.innerHTML = `Goal: $${intcomma(goal)}`;
  }

  setMercuryLevel(current, goal) {
    const percentage = Math.min(
      100,
      Math.max(0, Math.round((parseFloat(current) / parseFloat(goal)) * 100))
    );
    this.mercury.style.height = `${percentage}%`;
    this.mercury.dataset.percentage = percentage;
    this.mercury.setAttribute("title", `$${intcomma(current)} has been raised`);
  }
}

customElements.define("x-fundraising-thermometer", FundraisingThermometer);
